import '@fontsource/playfair-display/400-italic.css';
import '@fontsource/playfair-display/700.css';
import '@fontsource/raleway/400.css';
import '@fontsource/raleway/700.css';
import baseTheme from 'gatsby-plugin-theme-ui/src/index';

const theme = {
  ...baseTheme,

  colors: {
    primary: {
      __default: '#E09525',
      contrast: 'white',
      900: { __default: '#6C470F', contrast: 'white' },
      800: { __default: '#935F10', contrast: 'white' },
      700: { __default: '#B37314', contrast: 'white' },
      600: { __default: '#CC8314', contrast: 'white' },
      500: { __default: '#E09525', contrast: 'white' },
      400: { __default: '#EFB867' },
      300: { __default: '#F5CE94', contrast: 'black' },
      200: { __default: '#FCE0B5', contrast: 'black' },
      100: { __default: '#FFECD0', contrast: 'black' },
      50: { __default: '#FBF8F4', contrast: 'black' },
    },
    accent: {
      __default: '#919A3F',
      contrast: 'white',
      700: { __default: '#72792F', contrast: 'white' },
      500: { __default: '#919A3F', contrast: 'white' },
      400: { __default: '#AFB766' },
      300: { __default: '#C9CF91' },
      200: { __default: '#DBDFBF', contrast: 'black' },
      100: { __default: '#F1F2E8' },
      50: { __default: '#F9F9F5' },
    },
    body: 'black',
    header: 'white',
    overlay: 'rgba(255,255,255,0.98)',
  },

  space: {
    px: 1,
    0.5: 2,
    1: 4,
    1.5: 6,
    2: 8,
    2.5: 12,
    3: 16,
    3.5: 24,
    4: 32,
    4.5: 48,
    5: 64,
    6: 128,
    6.5: 192,
    7: 256,
  },

  sizes: {
    column: 192,
  },

  fonts: {
    heading: '"Playfair Display", serif',
    body: 'Raleway, sans-serif',
  },

  fontWeights: {
    heading: '700',
    body: '400',
    bold: '700',
    titleLarge: '700',
  },

  fontSizes: {
    small: 18,
    default: 20,
    body: 20,
    large: 24,
    subline: 24,
    headingSmall: 28,
    heading: 48,
    headingLarge: 54,
    titleSmall: 64,
    title: 96,
    titleLarge: 128,
  },

  lineHeights: {
    small: '28px',
    default: '28px',
    body: '36px',
    large: '40px',
    subline: '40px',
    headingSmall: '34px',
    heading: '58px',
    headingLarge: '68px',
    titleSmall: '80px',
    title: '120px',
    titleLarge: '160px',
  },

  borders: {
    1: '1px solid',
    2: '2px solid',
    4: '4px solid',
  },

  transitions: {
    color: 'color 0.3s ease-in-out',
  },

  links: {
    __default: { transition: 'color' },

    nav: {
      variant: 'links',
      display: 'inline-block',
      padding: 3,
      color: 'inherit',
      textDecoration: 'none',
      ':hover': { color: 'primary.700' },
    },

    mainMenu: {
      variant: 'links.nav',
      textTransform: 'uppercase',
      fontSize: 'small',
      lineHeight: 'small',
    },

    footer: {
      variant: 'links',
      color: 'white',
      transition: 'color',
      ':hover': { color: 'accent.200' },
    },
  },

  text: {
    headingSmall: {
      fontFamily: 'heading',
      fontSize: 'headingSmall',
      fontWeight: 'normal',
      lineHeight: 'headingSmall',
      letterSpacing: 'headingSmall',
      fontStyle: 'italic',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    heading: {
      fontFamily: 'heading',
      fontSize: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      letterSpacing: 'heading',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    title: {
      variant: 'text.heading',
    },

    titleLarge: {
      fontFamily: 'heading',
      fontSize: 'titleLarge',
      fontWeight: 'titleLarge',
      lineHeight: 'titleLarge',
      letterSpacing: 'titleLarge',
    },

    default: {
      fontFamily: 'body',
      fontSize: 'body',
      lineHeight: 'body',
    },
    small: {
      fontFamily: 'body',
      fontSize: 'small',
      lineHeight: 'small',
    },

    paragraph: {
      gridColumn: 'text',
      marginY: 3,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    discretion: {
      variant: 'styles.h2',
      gridColumn: 'content / text',
    },
  },

  styles: {
    root: {
      variant: 'text.default',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      backgroundColor: 'primary.50',
    },

    a: { variant: 'links', color: 'primary.600', textDecoration: 'none' },

    block: {
      default: {
        a: { color: 'inherit' },
        button: {
          backgroundColor: 'accent',
          'a&': { color: 'accent.contrast' }, // Matches a button as an a tag.
        },
        p: { marginX: 0, paddingX: 0 },
      },
      primaryDark: {
        variant: 'styles.block.default',
        backgroundColor: 'primary.700',
        color: 'primary.700.contrast',
      },
      primary: {
        variant: 'styles.block.default',
        backgroundColor: 'primary',
        color: 'primary.contrast',
        button: {
          backgroundColor: 'accent',
          'a&': { color: 'accent.contrast' }, // Matches a button as an a tag.
        },
      },
      secondary: {
        variant: 'styles.block.default',
        backgroundColor: 'primary.300',
        color: 'primary.300.contrast',
        button: {
          backgroundColor: 'accent',
          'a&': { color: 'accent.contrast' }, // Matches a button as an a tag.
        },
      },
      tertiary: {
        variant: 'styles.block.default',
        backgroundColor: 'primary.200',
        color: 'primary.200.contrast',
      },
      fourth: {
        variant: 'styles.block.default',
        backgroundColor: 'primary.100',
        color: 'primary.100.contrast',
      },
      accent: {
        variant: 'styles.block.default',
        backgroundColor: 'accent',
        color: 'accent.contrast',
      },
    },

    h1: {
      variant: 'text.heading',
      fontSize: ['heading', 'titleSmall', 'title', 'titleLarge'],
      lineHeight: ['heading', 'titleSmall', 'title', 'titleLarge'],
      gridColumn: 'content',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    subtitle: {
      variant: 'text.heading',
      fontSize: ['body', 'large'],
      lineHeight: ['body', 'large'],
      fontStyle: 'italic',
      fontWeight: 'normal',
    },

    h2: {
      variant: 'text.heading',
      gridColumn: 'text',
      fontSize: ['headingSmall', 'heading', null, 'headingLarge'],
      lineHeight: ['headingSmall', 'heading', null, 'headingLarge'],
      paddingTop: 4.5,
      paddingBottom: 3,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    h3: {
      variant: 'text.headingSmall',
      gridColumn: 'text',
      fontSize: ['large', 'headingSmall'],
      lineHeight: ['large', 'headingSmall'],
      paddingTop: 3,
      paddingBottom: 2,
    },

    h4: {
      variant: 'text.default',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },

    header: { gridColumn: 'content', marginX: [3, 4] },

    blockquote: { gridColumn: 'text', marginX: [3, 4] },

    ul: { gridColumn: 'text', marginX: [3, 4] },

    ol: { gridColumn: 'text', marginX: [3, 4] },
  },
};

console.log(theme);

export default theme;
